<template>
  <div>
    <!-- Include Font Awesome -->
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">

    <PageHeader image="header-2" withMobile :title="$lang('title')" :breadcrumbs="$lang('breadcr')" />

    <!-- Section 1 -->
    <section class="pt-8 pb-9 bg-green2">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-5 mb-5 mb-lg-0">
            <h2 class="fw-bold text-blue1 mb-4">
              {{ $lang("section1.title") }}
            </h2>
            <p class="text-white">
              {{ $lang("section1.text") }}
            </p>
          </div>
          <div class="col-lg-6 offset-lg-1">
            <ImageContainer fileName="careers-1" class="img-container-rounded" />
          </div>
        </div>
      </div>
    </section>

    <!-- Job Listings Section -->
    <section class="pt-8 pb-10 bg-light">
      <div class="container">
        <div v-if="jobListings.length">
          <div v-for="job in jobListings" :key="job.title" class="job-listing shadow-lg">
            <h2 class="job-title">{{ job.title }}</h2>
            <p class="company-info">Company: {{ job.company }}
              <a :href="job.social.indeed" target="_blank"><i class="fab fa-linkedin"></i></a>
              <a :href="job.social.facebook" target="_blank"><i class="fab fa-facebook"></i></a>
              <a :href="job.social.linkedin" target="_blank"><i class="fas fa-briefcase"></i></a>
            </p>
            <p class="job-description">{{ job.description }}</p>
            <p :style="{ color: job.status === 'Open' ? '#28a745' : '#dc3545' }" class="job-status">Status: {{ job.status }}</p>
            <div class="job-links">
              <a :href="job.linkedin" target="_blank" class="btn btn-primary">View on LinkedIn</a>
              <a :href="job.indeed" target="_blank" class="btn btn-secondary">View on Indeed</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";

export default {
  components: {
    PageHeader,
  },
  data() {
    return {
      jobListings: [
        {
          title: "Receptionist & Accommodation Officer (Full Time)",
          company: "NED College",
          status: "Open",
          description: "We are looking for a dedicated Receptionist & Accommodation Officer to join our Administration team at NED College English School in Dublin...",
          linkedin: "https://www.linkedin.com/jobs/view/3988111709",
          indeed: "#",
          social: {
            linkedin: "https://www.linkedin.com/company/ned-college",
            facebook: "https://www.facebook.com/nedcollege",
            indeed: "#"
          }
        },
        {
          title: "Receptionist & Accommodation Officer (Full Time)",
          company: "NED College",
          status: "Closed",
          description: "We are looking for a dedicated Receptionist & Accommodation Officer to join our Administration team at NED College English School in Dublin...",
          linkedin: "https://www.linkedin.com/jobs/view/3988111709",
          indeed: "#",
          social: {
            linkedin: "https://www.linkedin.com/company/ned-college",
            facebook: "https://www.facebook.com/nedcollege",
            indeed: "#"
          }
        }


        // Add more job listings here as needed
      ],
      
    };
  },
};
</script>

<style>
/* General Styles */
body {
  font-family: 'Roboto', sans-serif;
}

/* Job Listing Styles */
.job-listing {
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.job-listing:hover {
  transform: translateY(-5px);
}

.job-title {
  font-size: 26px;
  color: #d75b02;
  margin-bottom: 15px;
}

.company-info {
  font-size: 18px;
  color: #0052a3;
  margin-bottom: 10px;
  text-transform: bold;
}

.job-description {
  font-size: 16px;
  color: #000000;
  margin-bottom: 20px;
  text-align: justify;
}

.job-status {
  font-weight: bold;
  margin-bottom: 15px;
}

.job-links .btn {
  font-style: normal;
  font-weight: lighter;
  transition: backround-color 0.2s, color 0.2s;
  padding: 10px 15px;
  /* padding: 10px 20px; */
  border-radius: 5px;
  text-decoration: none;
  margin-right: 10px;
  /* transition: background-color 0.3s ease; */
}

.btn-primary {
  padding: 10px 15px;
  /* background-color: #0838e6; */
  border: 1px solid rgb(14, 118, 168);
  color:rgb(14, 118, 168);
}

.btn-primary:hover {
  background-color: #1a518b;
  color: #ffffff;
}

.btn-secondary {
  border: 1px solid rgb(255, 90, 31);
  /* background-color: rgb(red, green, blue); */
  color: rgb(255, 90, 31);
}

.btn-secondary:hover {
  background-color: rgb(255, 90, 31);
  color: white;
}

.job-listing i {
  font-size: 22px;
  margin-left: 15px;
  color: #007bff;
  transition: color 0.3s ease;
}

.job-listing i:hover {
  color: #0056b3;
}
</style>
